import * as React from 'react';
import { StylesProps } from '../../theme/jss-types';
import { ReduxStore } from '../../reducers/redux.types';
import GenericHoc from '../common/generic-hoc';
import { RouteChildrenProps } from 'react-router';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Radio,
    Row,
    Select,
    message,
} from 'antd';
import { addRequestStyles } from './add-request.styles';
import { HocOptions } from 'components/common/generic-hoc.types';
import Cross from '../../assets/cross';
import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import { formFields } from './additional-services.constants';
import { omit, uniqueId } from 'lodash';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    createSupplementaryRequest,
    fetchSupplementaryServiceMasterData,
    updateSupplementaryRequest,
} from 'network/supp-services.api';
import { useWatch } from 'antd/lib/form/Form';
import CreateAddressForm from 'components/address/create-address';
import CreateAddressForm2 from 'components/address/create-address2';
import { Master } from 'types/master-data-types';
import { LabelValue } from 'library/Types';
import { PickupAddress } from 'components/pickup/create-pickup/pickup.types';
import { deleteAddress, fetchPickupAddress } from 'network/pickup.api';
import Loader from 'components/common/Loader';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'hooks/use-debounce';
import Helper from 'library/Helper';
import { fetchConsignment, getChildList } from 'network/consignments.api';
import moment from 'moment';

interface AdditionalServiceProps
    extends StylesProps<ReturnType<typeof addRequestStyles>>,
    RouteChildrenProps {
    master: Master;
    onClose: (isSuccess?: boolean) => void;
    requestType: 'consignment' | 'general';
    cnReferenceNumberCreated?: string;
    editFormData?: Record<any, any>;
    autofillAddressFieldsBasedOnAddressHierarchy: boolean;
}

interface ServiceListItem {
    key: string;
    service_code?: string;
    quantity?: number;
    remarks?: string;
}

const {
    ConsignmentReferenceNumber,
    ServiceRequestTime,
    AddressType,
    Service,
    Quantity,
    Remarks,
    CustomerCode,
    // SupplyRequestID,
    // IsFulfilled,
    AddressRadio,
} = formFields;


const AddConsignmentRequestPage = (props: AdditionalServiceProps) => {
    const {
        classes,
        master,
        onClose,
        requestType,
        editFormData,
        cnReferenceNumberCreated,
        autofillAddressFieldsBasedOnAddressHierarchy,
    } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [serviceList, setServiceList] = React.useState<any>({});
    const addressRadioValue = useWatch([AddressRadio.key], form);
    const phoneRegex = master?.parts_to_show?.remove_regex ? null : master?.config?.phone_regex;
    const countryWisePhoneRegex = master?.config?.country_wise_phone_regex;
    const [serviceMasterList, setServiceMasterList] = React.useState<LabelValue[]>([]);
    const [customerCodeList, setCustomerCodeList] = React.useState<LabelValue[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [addressList, setAddressList] = React.useState<PickupAddress[]>([]);
    const [searchString, setSearchString] = React.useState<string>('');
    const [selectedAddress, setSelectedAddress] = React.useState<PickupAddress | undefined>(undefined);
    const [editAddressData, setEditAddressData] = React.useState<PickupAddress | null>(null);
    const [consignmentData, setConsignmentData] = React.useState<any>(null);
    const showAddressCode = master?.config?.customer_portal_config?.allow_address_code_in_addresses;
    const fetchNewCnData = async () => {
        if (!cnReferenceNumberCreated) return;
        const response = await fetchConsignment({ reference_number: cnReferenceNumberCreated });
        if (response?.isSuccess && response?.data?.length) {
            setConsignmentData(response?.data?.length ? response?.data[0] : {});
        } else {
            message.error(response?.errorMessage || 'Consignment not found!');
        }
    };

    React.useEffect(() => {
        fetchNewCnData();
    }, [cnReferenceNumberCreated]);


    const loadAddress = async () => {
        if (requestType !== 'general') return;
        if (addressRadioValue !== 'saved') {
            return;
        }
        setLoading(true);
        const response = await fetchPickupAddress({
            searchString: searchString || '',
        });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        }
        setAddressList(response?.data || []);
        setLoading(false);
    };

    const loadAccounts = async () => {
        if (requestType !== 'general') return;
        setLoading(true);
        const result = await getChildList();
        setCustomerCodeList(result?.data?.map((cust: any) => {
            return {
                label: cust?.customer_code,
                value: cust?.customer_code,
            };
        }) || []);
        const customer = result?.data?.length ? result.data[0] : undefined;
        form.setFieldsValue({
            [CustomerCode.key]: customer?.customer_code,
        });
        setLoading(false);
    };

    const addService = () => {
        const key = uniqueId('new_service_');
        setServiceList({
            ...(serviceList || {}),
            [key]: {
                key,
                service_code: '',
                quantity: undefined,
                remarks: '',
            },
        });
    };

    const loadAvailableServiceTypes = async () => {
        const response = await fetchSupplementaryServiceMasterData();
        if (response?.isSuccess) {
            const data = response?.data?.page_data || {};
            const supplementoryMasterListData = data
                .map((obj: any) => {
                    return (
                        {
                            value: obj.code,
                            label: obj.name,
                            applicable_on: obj.applicable_on,
                        });
                });
            const filteredDataByApplicableFileds: any[] = [];
            supplementoryMasterListData.forEach((obj: any) => {
                const applicableOn = obj.applicable_on || [];
                if (Array.isArray(applicableOn) && applicableOn.length && applicableOn.includes(requestType.toUpperCase())) {
                    filteredDataByApplicableFileds.push(obj);
                }
            });
            setServiceMasterList(filteredDataByApplicableFileds);
            console.log(filteredDataByApplicableFileds);
        }
    };

    const debounceTimeSrc = useDebounce(searchString);
    React.useEffect(() => {
        loadAddress();
    }, [debounceTimeSrc, addressRadioValue]);

    const getServiceListEdit = () => {
        const services: Record<any, any> = {};
        editFormData?.supplementary_service_list?.forEach((service: any) => {
            const index = uniqueId('existing_service_');
            services[index] = {
                key: index,
                ...service,
            };
        });
        setServiceList(services);
        return services;
    };

    React.useEffect(() => {
        getServiceListEdit();
        loadAvailableServiceTypes();
        loadAccounts();
        console.log('services', serviceList);
        if (editFormData) {
            form.setFieldsValue({
                ...editFormData,
                services: getServiceListEdit(),
                [ServiceRequestTime.key]: moment(editFormData?.request_time || new Date()),
            });
        } else {
            addService();
            loadAddress();
        }
    }, []);

    const convertSupplementaryServiceList = (services: Record<any, any>) => {
        const serviceListApiData: any[] = [];
        Object.keys(services).forEach((key: any) => {
            const service = {
                ...serviceList[key],
                ...services[key],
            };
            serviceListApiData.push(omit(service, 'key'));
        });
        return serviceListApiData;
    };

    const handleSubmit = async () => {
        await form.validateFields();
        const data = form.getFieldsValue();
        if (requestType === 'general') {
            const apiData = {
                request_type: 'GENERAL',
                // request_name: data[SupplyRequestID.key],
                customer_code: data[CustomerCode.key],
                address_details: {
                    name: selectedAddress?.name,
                    phone: selectedAddress?.phone,
                    alternate_phone: selectedAddress?.alternatePhone,
                    address_line_1: selectedAddress?.addressLine1,
                    address_line_2: selectedAddress?.addressLine2,
                    pincode: selectedAddress?.pincode,
                    city: selectedAddress?.cityName,
                    state: selectedAddress?.stateName,
                    country: selectedAddress?.countryName,
                },
                service_request_date: data[ServiceRequestTime.key]
                    ? moment(data[ServiceRequestTime.key]).format('YYYY-MM-DD') : undefined,
                service_request_time: data[ServiceRequestTime.key]
                    ? moment(data[ServiceRequestTime.key]).format('HH:mm:ss') : undefined,
                // is_fulfilled: data[IsFulfilled.key],
                supplementary_service_list: convertSupplementaryServiceList(data?.service_list || {}),
            };
            const response = await createSupplementaryRequest(apiData);
            if (response?.isSuccess) {
                message.success('Request Created Successfully');
                onClose(true);
            } else {
                message.error(response?.errorMessage || 'Something went wrong!');
            }
        } else if (requestType === 'consignment') {
            const apiData = {
                request_type: 'CONSIGNMENT',
                // request_name: data[SupplyRequestID.key],
                customer_code: data[CustomerCode.key],
                consignment_reference_number: data[ConsignmentReferenceNumber.key],
                address_type: data[AddressType.key],
                address_details: data[AddressType.key] === 'pickup' ? {
                    name: consignmentData?.sender_name,
                    phone: consignmentData?.sender_phone,
                    alternate_phone: consignmentData?.sender_alternate_phone,
                    address_line_1: consignmentData?.sender_address_line_1,
                    address_line_2: consignmentData?.sender_address_line_2,
                    pincode: consignmentData?.sender_address_pincode,
                    city: consignmentData?.sender_city,
                    state: consignmentData?.sender_state,
                    country: consignmentData?.sender_country,
                } : {
                    name: consignmentData?.destination_name,
                    phone: consignmentData?.destination_phone,
                    alternate_phone: consignmentData?.destination_alternate_phone,
                    address_line_1: consignmentData?.destination_address_line_1,
                    address_line_2: consignmentData?.destination_address_line_2,
                    pincode: consignmentData?.destination_pincode,
                    city: consignmentData?.destination_city,
                    state: consignmentData?.destination_state,
                    country: consignmentData?.destination_country,
                },
                service_request_date: data[ServiceRequestTime.key]
                    ? moment(data[ServiceRequestTime.key]).format('YYYY-MM-DD') : undefined,
                service_request_time: data[ServiceRequestTime.key]
                    ? moment(data[ServiceRequestTime.key]).format('HH:mm:ss') : undefined,
                // is_fulfilled: true,
                supplementary_service_list: convertSupplementaryServiceList(data?.service_list || {}),
            };
            const response = await createSupplementaryRequest(apiData);
            if (response?.isSuccess) {
                message.success('Request Created Successfully');
                onClose(true);
            } else {
                message.error(response?.errorMessage || 'Something went wrong!');
            }
        }
    };

    const handleEditSubmit = async () => {
        await form.validateFields();
        const data = form.getFieldsValue();

        const apiData = {
            supplementary_service_request_id: editFormData?.id,
            supplementary_service_list: convertSupplementaryServiceList(data?.service_list || {}),
            action_type: 'EDIT',
        };
        const response = await updateSupplementaryRequest(apiData);
        if (response?.isSuccess) {
            message.success('Request Created Successfully');
            onClose(true);
        } else {
            message.error(response?.errorMessage || 'Something went wrong!');
        }
    };

    const handleBlurReferenceNumber = async () => {
        const referenceNumber = form.getFieldValue(ConsignmentReferenceNumber.key);
        if (!referenceNumber) return;
        setLoading(true);
        const response = await fetchConsignment({ reference_number: referenceNumber });
        if (response?.isSuccess && response?.data?.length) {
            setConsignmentData(response?.data?.length ? response?.data[0] : {});
        } else {
            message.error(response?.errorMessage || 'Consignment not found!');
        }
        setLoading(false);
        form.validateFields([ConsignmentReferenceNumber.key]);
    };

    const renderFormItem = (field: FormField | undefined, service: ServiceListItem | undefined = undefined) => {
        if (!field) {
            return (
                <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }} />
            );
        }
        // const defaultValue: any = field.defaultValue;
        const defaultValue: any = editFormData && service
            ? serviceList[service.key][field.key] : field.defaultValue;
        switch (field.type) {
            case InputTypes.Input:
                return (
                    <Form.Item
                        name={service ? ['service_list', service.key, field.key] : field.key}
                        label={service ? undefined : t(field.label)}
                        initialValue={(field.key === ConsignmentReferenceNumber.key) && cnReferenceNumberCreated
                            ? cnReferenceNumberCreated : defaultValue}
                        rules={field.key === ConsignmentReferenceNumber.key ? [
                            {
                                validator: (rule, value, cb) => {
                                    if (!consignmentData && !editFormData) {
                                        return cb('Enter Valid reference number');
                                    }
                                    return cb();
                                },
                            },
                        ] : undefined}
                    >
                        <Input
                            className={classes.input}
                            width={field.width}
                            style={{ width: field.width }}
                            type="text"
                            disabled={Boolean(!service && editFormData)}
                            placeholder={t(field.label)}
                            autoComplete="off"
                            onBlur={field.key === ConsignmentReferenceNumber.key ? handleBlurReferenceNumber : undefined}
                        />
                    </Form.Item>
                );
            case InputTypes.Select:
                return (
                    <Form.Item
                        name={service ? ['service_list', service.key, field.key] : field.key}
                        label={service ? undefined : t(field.label)}
                        initialValue={defaultValue}
                    >
                        <Select
                            // className={classes.input}
                            style={{ width: field.width }}
                            disabled={Boolean(!service && editFormData)}
                            // eslint-disable-next-line no-nested-ternary
                            options={field.key === Service.key ? serviceMasterList : (
                                CustomerCode.key === field.key ? customerCodeList : field.options
                            )}
                        />
                    </Form.Item>
                );
            case InputTypes.Time:
                return (
                    <Form.Item
                        name={service ? ['service_list', service.key, field.key] : field.key}
                        label={service ? undefined : t(field.label)}
                        initialValue={field.defaultValue}
                    >
                        <DatePicker
                            showTime
                            style={{ width: field.width }}
                            disabled={Boolean(!service && editFormData)}
                            placeholder={t(field.label)}
                        />
                    </Form.Item>
                );
            case InputTypes.Number:
                return (
                    <Form.Item
                        name={service ? ['service_list', service.key, field.key] : field.key}
                        label={service ? undefined : t(field.label)}
                        initialValue={defaultValue}
                    >
                        <InputNumber
                            className={classes.input}
                            width={field.width}
                            disabled={Boolean(!service && editFormData)}
                            style={{ width: field.width }}
                            placeholder={t(field.label)}
                        />
                    </Form.Item>
                );
            case InputTypes.Radio:
                return (
                    <Form.Item
                        name={service ? ['service_list', service.key, field.key] : field.key}
                        label={service ? undefined : t(field.label)}
                        initialValue={field.defaultValue}
                    >
                        <Radio.Group
                            options={field.options && field.options.map((option) => ({
                                ...option,
                                label: t(option.label),
                            }))}
                            disabled={Boolean(!service && editFormData)}
                        />
                    </Form.Item>
                );
            case InputTypes.Checkbox:
                return (
                    <Form.Item
                        name={service ? ['service_list', service.key, field.key] : field.key}
                        label={' '}
                        initialValue={field.defaultValue}
                    >
                        <div className={classes.flexRow}>
                            <Checkbox disabled={Boolean(!service && editFormData)} />
                            &nbsp;&nbsp;&nbsp;
                            {t(field.label)}
                        </div>
                    </Form.Item>
                );
            default:
                return <h1>{t('Not Handled')}</h1>;
        }
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <Cross onClick={() => onClose(false)} alt="close" className={classes.closeIcon} />
                    { requestType === 'consignment' && (
                        <span>
                            {editFormData ? t('Edit ') : t('Add ')}
                            {t('Supplementary Request for Consignment')}
                        </span>
                    )}
                    { requestType === 'general' && (
                        <span>
                            {editFormData ? t('Edit ') : t('Add ')}
                            {t('General Request')}
                        </span>
                    )}
                </div>
                <Button
                    type="primary"
                    onClick={() => (editFormData ? handleEditSubmit() : handleSubmit())}
                    style={{ marginRight: 16 }}
                    // loading={creating}
                    // disabled
                >
                    {t('Submit')}
                </Button>
            </div>
        );
    };

    const renderDeleteService = (service: ServiceListItem) => {
        if (Object.keys(serviceList).length < 2) return null;
        return (
            <DeleteOutlined
                onClick={() => {
                    setServiceList(omit(serviceList, [service.key]));
                }}
            />
        );
    };

    const showAddCode = (item: PickupAddress) => {
        return (
            <span style={{ display: 'flex', fontWeight: 'bold' }}>
                <span>{item?.addressCode}</span>
                <span style={{ margin: '0px 6px 0px 4px', fontWeight: 'bold' }}> | </span>
            </span>
        );
    };

    const renderEdit = (item: PickupAddress) => {
        return (
            <EditOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    setEditAddressData(item);
                    form.setFieldsValue({
                        [AddressRadio.key]: 'create',
                    });
                }}
                // style={{ color: uiTheme.primaryColor, marginRight: 8 }}
            />
        );
    };

    const handleDelete = async (e: any, id: string) => {
        Helper.stops(e);
        const result = await deleteAddress({ id });
        if (result.isSuccess) {
            message.success(t('Address Deleted Successfully'));
            loadAddress();
        } else {
            message.error(result.errorMessage);
        }
    };

    const renderDelete = (item: PickupAddress) => {
        return (
            <Popconfirm
                placement="topLeft"
                title={t('delete_address_text')}
                onConfirm={(e) => handleDelete(e, item.id)}
                okText={t('ok_text')}
                cancelText={t('cancel')}
                onCancel={(e) => Helper.stops(e as any)}
            >
                <DeleteOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ color: 'red' }}
                />
            </Popconfirm>
        );
    };

    const renderCheckbox = (item: PickupAddress) => {
        return (
            <Checkbox
                key={item.id}
                checked={selectedAddress?.id === item.id}
                className={classes.checkbox}
            >
                <div className={classes.addressName}>
                    <span style={{ display: 'flex' }}>
                        {showAddressCode && item?.addressCode ? showAddCode(item) : null}
                        <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
                            {item.name || ''}
                        </span>
                    </span>
                    <div style={{ justifyContent: 'flex-end' }}>
                        {renderEdit(item)}
                        {renderDelete(item)}
                    </div>
                </div>
            </Checkbox>
        );
    };

    const renderAddressDetails = (item: PickupAddress) => {
        const customerCode = window.localStorage.getItem('userCode');
        return (
            <div className={classes.addressLineDetails}>
                <div style={{ minWidth: '30%' }}>
                    {item.addressLine1 || ''}
                    {item.addressLine1 ? ', ' : ''}
                    {item.addressLine2 || ''}
                </div>
                <div style={{ minWidth: '30%', marginLeft: '10%' }}>
                    {item.pincode || ''}
                    {item.pincode && item.cityName ? ' - ' : ''}
                    {item.cityName || ''}
                    <br />
                    {item.stateName || ''}
                    {item.stateName ? ', ' : ''}
                    {item.countryName || ''}
                </div>
                <div style={{ minWidth: '30%', textAlign: 'right' }}>
                    {item.phone
                        ? (
                            <>
                                {item?.phone}
                                <br />
                            </>
                        ) : null}
                    {item.alternatePhone
                        ? (
                            <>
                                {item?.alternatePhone}
                                <br />
                            </>
                        ) : null}
                    {item.email ? item.email : null}
                    <b>{`${item.locationId || ''}`}</b>
                    <br />
                    <b>{item.locationId ? item.locationCode || `${customerCode}_${item.locationId}` : ''}</b>
                </div>
            </div>
        );
    };

    const renderAddressLine = (item: PickupAddress) => {
        let className = classes.addressLine;
        const isCurrentSelected = item?.id === selectedAddress?.id;
        if (isCurrentSelected) {
            className = [classes.addressLineSelected, classes.addressLine].join(' ');
        }
        return (
            <div
                key={item.id}
                onClick={() => {
                    if (isCurrentSelected) setSelectedAddress(undefined);
                    else setSelectedAddress(item);
                }}
                className={className}
            >
                {renderCheckbox(item)}
                {renderAddressDetails(item)}
            </div>
        );
    };

    const renderAddressList = () => {
        if (loading) {
            return <Loader zIndex={10} />;
        }
        return (
            <div className={classes.addressList}>
                {addressList.map((item: PickupAddress) => renderAddressLine(item))}
            </div>
        );
    };

    const renderCreateConsignmentForm = () => {
        return (
            <>
                <Row gutter={10}>
                    <Col span={12}>
                        {renderFormItem(ConsignmentReferenceNumber)}
                    </Col>
                    <Col span={12}>
                        {renderFormItem(ServiceRequestTime)}
                    </Col>
                    <Col span={12}>
                        {renderFormItem(AddressType)}
                    </Col>
                    {/* <Col span={12}>
                        {renderFormItem(SupplyRequestID)}
                    </Col> */}
                </Row>
                <hr />
                <Row gutter={10}>
                    <Col span={7}>
                        <span className="ant-col ant-col-24 ant-form-item-label">{t(Service.label)}</span>
                    </Col>
                    <Col span={7}>
                        <span className="ant-col ant-col-24 ant-form-item-label">{t(Quantity.label)}</span>
                    </Col>
                    <Col span={7}>
                        <span className="ant-col ant-col-24 ant-form-item-label">{t(Remarks.label)}</span>
                    </Col>
                    <Col span={3}>
                        <span className="ant-col ant-col-24 ant-form-item-label">&nbsp;</span>
                    </Col>
                </Row>
                {Object.keys(serviceList).map((key: any) => {
                    const service = serviceList[key];
                    return (
                        <Row gutter={10} key={service.key} style={{ margin: '10px 0' }}>
                            <Col span={7}>
                                {renderFormItem(Service, service)}
                            </Col>
                            <Col span={7}>
                                {renderFormItem(Quantity, service)}
                            </Col>
                            <Col span={7}>
                                {renderFormItem(Remarks, service)}
                            </Col>
                            <Col span={3}>
                                {renderDeleteService(service)}
                            </Col>
                        </Row>
                    );
                })}
                <Button
                    type="link"
                    onClick={addService}
                >
                    +
                    {t('Add More')}
                </Button>
            </>
        );
    };

    const renderAddressSearch = () => {
        return (
            <div className={classes.searchAddress}>
                <Input
                    placeholder={t('search')}
                    type="search"
                    onChange={(e) => setSearchString(e.target.value || '')}
                    style={{ width: 200 }}
                />
            </div>
        );
    };

    const renderExistingAddress = () => {
        return (
            <>
                {renderAddressSearch()}
                <hr />
                {renderAddressList()}
            </>
        );
    };

    const renderCreateGeneralForm = () => {
        return (
            <>
                <Row gutter={10}>
                    <Col span={12}>
                        {renderFormItem(CustomerCode)}
                    </Col>
                    {/* <Col span={12}>
                        {renderFormItem(SupplyRequestID)}
                    </Col> */}
                    <Col span={12}>
                        {renderFormItem(ServiceRequestTime)}
                    </Col>
                    {/* <Col span={12}>
                        {renderFormItem(IsFulfilled)}
                    </Col> */}
                    <Col span={12}>
                        {renderFormItem(AddressRadio)}
                    </Col>
                </Row>
                {addressRadioValue === 'create' && !editFormData && !autofillAddressFieldsBasedOnAddressHierarchy && (
                    <CreateAddressForm
                        editData={editAddressData}
                        onClose={() => {
                            setEditAddressData(null);
                            form.setFieldsValue({ [AddressRadio.key]: 'saved' });
                        }}
                        phoneRegex={phoneRegex}
                        countryWisePhoneRegex={countryWisePhoneRegex}
                    />
                )}
                {addressRadioValue === 'create' && !editFormData && autofillAddressFieldsBasedOnAddressHierarchy && (
                    <CreateAddressForm2
                        editData={editAddressData}
                        onClose={() => {
                            setEditAddressData(null);
                            form.setFieldsValue({ [AddressRadio.key]: 'saved' });
                        }}
                        phoneRegex={phoneRegex}
                        countryWisePhoneRegex={countryWisePhoneRegex}
                    />
                )}
                {addressRadioValue === 'saved' && !editFormData && renderExistingAddress()}
                <hr />
                <Row gutter={10}>
                    <Col span={7}>
                        <span className="ant-col ant-col-24 ant-form-item-label">{t(Service.label)}</span>
                    </Col>
                    <Col span={7}>
                        <span className="ant-col ant-col-24 ant-form-item-label">{t(Quantity.label)}</span>
                    </Col>
                    <Col span={7}>
                        <span className="ant-col ant-col-24 ant-form-item-label">{t(Remarks.label)}</span>
                    </Col>
                    <Col span={3}>
                        <span className="ant-col ant-col-24 ant-form-item-label">&nbsp;</span>
                    </Col>
                </Row>
                {Object.keys(serviceList).map((key: any) => {
                    const service = serviceList[key];
                    return (
                        <Row gutter={10} key={service.key} style={{ margin: '10px 0' }}>
                            <Col span={7}>
                                {renderFormItem(Service, service)}
                            </Col>
                            <Col span={7}>
                                {renderFormItem(Quantity, service)}
                            </Col>
                            <Col span={7}>
                                {renderFormItem(Remarks, service)}
                            </Col>
                            <Col span={3}>
                                {renderDeleteService(service)}
                            </Col>
                        </Row>
                    );
                })}
                <Button
                    type="link"
                    onClick={addService}
                >
                    +
                    {t('Add More')}
                </Button>
            </>
        );
    };

    return (
        <Drawer
            className={classes.main}
            title={renderHeader()}
            placement="right"
            width="50%"
            closable={false}
            visible
            onClose={() => onClose(false)}
            footer={null}
        >
            <div className={classes.content}>
                <Form form={form} layout="vertical">
                    {requestType === 'consignment' && renderCreateConsignmentForm()}
                    {requestType === 'general' && renderCreateGeneralForm()}
                </Form>
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const { SUPP_SERVICES } = generic;
    const {
        loading,
        data,
        pagination,
    } = SUPP_SERVICES;
    return {
        loading,
        SuppServicesData: data,
        uiTheme: state.uiTheme,
        pagination,
        autofillAddressFieldsBasedOnAddressHierarchy: state?.master?.parts_to_show
            ?.autofill_address_fields_based_on_address_hierarchy,
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: addRequestStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const AddConsignmentRequest = GenericHoc(hocConfig)(AddConsignmentRequestPage);
export default AddConsignmentRequest;
