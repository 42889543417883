import * as React from 'react';
import { StylesProps, ThemeType } from '../../../theme/jss-types';
import { singleConsignmentStyles } from '../single-consignment.styles';
import withStyles from 'react-jss';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import Cross from '../../../assets/cross';
import { omitBy } from 'lodash';
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import {
    ButtonNames, CreateActions, formFields,
    validationBuilderFieldMapping,
} from '../create-modal.constants';
import {
    ButtonAction,
} from '../create-consignment.types';
import {
    Button,
    Drawer,
    Form,
    Modal,
    Row,
    message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getFormFieldFromMapping } from './components/generic-field-renderer';
import {
    createConsignment,
    getChildList,
    getContentList,
    getCurrencyCodes,
    getIncoTerms,
    getPudoHubs,
    getValidationData,
} from 'network/consignments.api';
import { Category, Commodity, PudoHubs } from 'library/Types';
import Loader from 'components/common/Loader';
import { getCountries } from 'network/common.api';
import { getLoadType } from 'network/pickup.api';
import { convertToAPIBodySaveSingle, isEmptyValue } from './single-consignment-helper';
import sift from 'sift';

const lodash = require('lodash');

type DataInputType = Record<string, any>;
type ReferenceObjectType =Record<string, any>;

interface SingleConsignmentProps extends StylesProps<ReturnType<typeof singleConsignmentStyles>>,
    ReturnType<typeof mapStateToProps> {
    // config: any;
    onClose: () => void;
    setSuccess: (data: any) => void;
    loadconsignments: () => void;
    // isRTL: any;
    uiTheme: ThemeType;
    editData?: any;
    formLayout: any;
    actionType?: any;
    shipmentPurposeList: any;
    subLayout: any;
    customFieldsList: any;
}

const SingleConsignment = (props: SingleConsignmentProps) => {
    const {
        // config,
        classes,
        onClose,
        // isRTL,
        uiTheme,
        editData,
        formLayout,
        mandatoryFieldNonDocIntl,
        mandatoryFieldShipmentCommercial,
        mandatoryPieceDetails,
        showRiskSurchargeType,
        loadconsignments,
        showInvoiceOptions,
        setSuccess,
        useCategory,
        actionType,
        shipmentPurposeList,
        subLayout,
        customFieldsList,
    } = props;
    const { t, i18n } = useTranslation();

    const [form] = Form.useForm();

    const [closeConfirm, setCloseConfirm] = React.useState<boolean>(false);
    const [visited, setVisited] = React.useState<{[key: number]: boolean | undefined | null}>({});
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
    const [creating, setCreating] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [childClients, setChildClients] = React.useState<any>([]);
    const [pudoHubs, setPudoHubs] = React.useState<PudoHubs[]>([]);
    const [contentList, setContentList] = React.useState<Commodity[]>([]); // old
    const [categoryList, setCategoryList] = React.useState<Category[]>([]); // new
    const [currencyList, setCurrencyList] = React.useState<any[]>([]);
    const [countryList, setCountryList] = React.useState<any[]>([]);
    const [incotermsList, setIncotermsList] = React.useState<any>([]);
    const [loadType, setLoadType] = React.useState<any>([]);
    const [validationData, setValidationData] = React.useState<any>([]);
    const [validationBuilderKeysMapping, setValidationBuilderKeysMapping] = React.useState<any>([]);

    const { ConsignmentCategory, ItemType } = formFields;
    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);

    const addCustomFieldsToValidationBuilderMapping = () => {
        const customFieldsKeys: string[] = customFieldsList?.map((item: any) => ({ [item.id]: item.id }));
        const customFieldsKeysObject = customFieldsKeys?.reduce((acc, obj: any) => ({ ...acc, ...obj }), {});
        setValidationBuilderKeysMapping({ ...validationBuilderFieldMapping, ...customFieldsKeysObject });
    };

    React.useEffect(() => {
        setVisited({
            ...visited,
            [currentTabIndex]: true,
        });
        addCustomFieldsToValidationBuilderMapping();
    }, [currentTabIndex]);

    const loadValidationData = async () => {
        const result = await getValidationData('consignment_softdata_validation');
        setValidationData(result?.data?.validations || []);
    };

    const loadAccounts = async () => {
        const result = await getChildList();
        setChildClients(result?.data || []);
        const customer = result?.data?.length ? result.data[0] : undefined;
        form.setFieldsValue({
            [formFields.SubAccount.key]: form.getFieldValue(formFields.SubAccount.key)
                || `${customer.customer_code}+${customer.customer_id}`,
        });
    };

    const loadPudoHubs = async () => {
        const response = await getPudoHubs();
        setPudoHubs(response?.data || []);
    };

    const loadContents = async () => {
        const response = await getContentList({
            isInternational: false,
            useCommodityCategory: useCategory,
        });
        if (useCategory) {
            setCategoryList(response?.data);
        } else {
            setContentList(response?.data);
        }
    };

    const loadCurrencyCodes = async () => {
        const response = await getCurrencyCodes();
        setCurrencyList(response?.data);
    };

    const loadCountries = async () => {
        const countriesResponse = await getCountries();
        if (countriesResponse.isSuccess) {
            setCountryList(countriesResponse.data.map((obj: any) => {
                return {
                    value: obj.country,
                    label: obj.country,
                };
            }));
        }
    };

    const loadIncoterms = async () => {
        const response = await getIncoTerms();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setIncotermsList(
                response?.data.map((incoTerm: any) => {
                    return {
                        label: incoTerm,
                        value: incoTerm,
                    };
                }),
            );
        }
    };

    const loadLoadTypeList = async () => {
        const response = await getLoadType();
        setLoadType(response?.data || []);
    };

    const loadData = async () => {
        setLoading(true);
        await Promise.all([
            loadAccounts(),
            loadPudoHubs(),
            loadContents(),
            loadCurrencyCodes(),
            loadCountries(),
            loadIncoterms(),
            loadLoadTypeList(),
            loadValidationData(),
        ]);
        setLoading(false);
    };

    React.useEffect(() => {
        loadData();
    }, []);

    function flattenObject(obj: any, parentKey = '') {
        return Object.keys(obj).reduce((acc, key) => {
            const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
                // Recursively flatten nested objects
                Object.assign(acc, flattenObject(obj[key], prefixedKey));
            } else {
                // Add the flattened key-value pair to the accumulator
                acc[prefixedKey] = obj[key];
            }
            return acc;
        }, {} as any);
    }

    function unflattenObject(flatObj: any) {
        const unflatten = (obj: any) => {
            const result = {};
            const objKeys = Object.keys(obj);
            objKeys.forEach((key) => {
                const keys = key.split('.');
                let nestedObj: any = result;
                for (let i = 0; i < keys.length - 1; i += 1) {
                    const nestedKey = keys[i];
                    if (!(nestedKey in nestedObj)) {
                        nestedObj[nestedKey] = {};
                    }
                    nestedObj = nestedObj[nestedKey];
                }
                nestedObj[keys[keys.length - 1]] = obj[key];
            });
            return result;
        };

        return unflatten(flatObj);
    }
    const replaceReferenceInObject = (obj: any, refObj: DataInputType): any => {
        if (typeof obj !== 'object') {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map((value) => {
                if (value && typeof value === 'object' && value.$reference) {
                    const path = value.$reference;
                    return lodash.get(refObj, path);
                }
                return replaceReferenceInObject(value, refObj);
            });
        }
        const result = { ...obj };
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (value && typeof value === 'object' && value.$reference) {
                const path = value.$reference;
                const valueToSet = lodash.get(refObj, path);
                result[key] = valueToSet;
            } else {
                result[key] = replaceReferenceInObject(value, refObj);
            }
        });
        return result;
    };
    const referenceReplacer = (referenceObject: ReferenceObjectType, dataInput: DataInputType): ReferenceObjectType => {
        const newRefObj = lodash.cloneDeep(referenceObject);
        const result = replaceReferenceInObject(newRefObj, dataInput);
        return result;
    };
    function validateDataUsingValidationBuilder(validateAllFields = false) {
        if (!formLayout) return true;
        const formLayoutDataToValidate = [];
        if (validateAllFields) {
            formLayoutDataToValidate.push(...formLayout);
        } else {
            formLayoutDataToValidate.push(formLayout[currentTabIndex]);
        }
        const fields = formLayoutDataToValidate.map(
            (layout: any) => layout?.sections.map((section: any) => {
                const fieldsList = section?.fieldsList ?? [];
                return fieldsList.map((field: string) => {
                    if (subLayout[field]) {
                        return [...(subLayout[field] || [])];
                    }
                    return field;
                }).flat();
            }).flat(),
        ).flat() ?? [];
        const mappedFields = Object.keys(validationBuilderKeysMapping).filter((key) => {
            return fields.some((field: string) => key.includes(field));
        }).map((key: string) => validationBuilderKeysMapping[key]);
        const filteredValidations = validationData.filter((data: any) => {
            const condition = data.filter.what || {};
            /* eslint-disable */
            for (const key of Object.keys(condition)) {
                if (key === '$or' || key === '$and') {
                    for (const orKey in condition[key]) {
                        const trimmedKey = orKey.startsWith('consignmentData.') ? orKey.replace('consignmentData.', '') : null;
                        if (trimmedKey && mappedFields.includes(trimmedKey)) {
                            return true;
                        }
                    }
                } else {
                    const trimmedKey = key.startsWith('consignmentData.') ? key.replace('consignmentData.', '') : null;
                    if (trimmedKey && mappedFields.includes(trimmedKey)) {
                        return true;
                    }
                }
            }
            return false;
        });
        const formData: any = flattenObject(convertToAPIBodySaveSingle(form.getFieldsValue(), { isCustomFieldAllowed: true, customFieldsList }));
        const dataFormatted: any = {
            consignmentData: {},
        };
        Object.keys(formData).forEach((key) => {
            const intKeys = [
                'length',
                'width',
                'height',
                'cod_amount',
                'cop_amount',
                'weight',
                'destination_details.latitude',
                'destination_details.longitude',
                'return_details.latitude',
                'return_details.longitude',
                'origin_details.latitude',
                'origin_details.longitude',
                'alternate_delivery_details_1.latitude',
                'alternate_delivery_details_1.longitude',
                'alternate_delivery_details_2.latitude',
                'alternate_delivery_details_2.longitude',
                'redirection_address.latitude',
                'redirection_address.longitude',
                'declared_value',
                'num_pieces',
                'freight_cost',
                'fob_value',
                'total_gst_paid_amount',
                'invoice_amount',
                'customs_value.amount',
            ];
            if (validationBuilderKeysMapping[key]) {
                dataFormatted.consignmentData[validationBuilderKeysMapping[key]] = formData[key];
                if (formData[key] && intKeys.includes(validationBuilderKeysMapping[key])) {
                    dataFormatted.consignmentData[validationBuilderKeysMapping[key]] = +formData[key];
                }
            }
        });

        dataFormatted.consignmentData = unflattenObject(omitBy(dataFormatted.consignmentData, isEmptyValue));
        let result = true;
        for (const data of filteredValidations) {
            let transformedWhenCondition = data?.filter?.when ?? {};
            transformedWhenCondition = referenceReplacer(transformedWhenCondition, dataFormatted);
            const siftedWhen = sift(transformedWhenCondition);
            if (siftedWhen(dataFormatted)) {
                let transformedWhatCondition = data?.filter?.what ?? {};
                transformedWhatCondition = referenceReplacer(transformedWhatCondition, dataFormatted);
                    const sifted = sift(transformedWhatCondition);
                    result = result && sifted(dataFormatted);
                    if (!result) {
                        message.error(data.error);
                        break;
                    }
                }
            }
        return result;
    }

    const handleTabChange = async (tabIndex: number) => {
        // updateFormData();
        // await customCheck();
        if (tabIndex === currentTabIndex) {
            return;
        }
        if (validateDataUsingValidationBuilder()) {
            setCurrentTabIndex(tabIndex);
        }
    };

    const renderFilledCircle = (tabIndex: number) => {
        const activePage = tabIndex === currentTabIndex;
        return (
            <div
                className={activePage ? classes.filledCircle : classes.filledCircle}
            />
        );
    };

    const renderCircle = (tabIndex: number) => {
        const activePage = tabIndex === currentTabIndex;
        return (
            <div
                className={classes.tabIcon}
                style={{
                    border: activePage ? `2px solid ${uiTheme.primaryColor}`
                        : '2px solid #CCCCCC',
                    backgroundColor: activePage ? uiTheme.primaryColor : '#FFF',
                }}
            >
                {renderFilledCircle(tabIndex)}
            </div>
        );
    };

    const complete = () => {
        return (
            <CheckCircleFilled
                style={{
                    color: '#27B479',
                    fontSize: 24,
                }}
            />
        );
    };

    const inComplete = () => {
        return (
            <ExclamationCircleFilled
                style={{
                    color: '#EA2626',
                    fontSize: 24,
                }}
            />
        );
    };

    const completeIncompleteIcon = () => {
        const err = false;
        if (err) {
            return inComplete();
        }
        return complete();
    };

    const renderTabIcon = (tabIndex: number) => {
        if (tabIndex === currentTabIndex) {
            return renderCircle(currentTabIndex);
        }
        if (visited[tabIndex]) {
            return completeIncompleteIcon();
        }
        return renderCircle(tabIndex);
    };

    const renderTabDetails = (tabIndex: number) => {
        const activePage = tabIndex === currentTabIndex;
        return (
            <div
                style={{
                    color: activePage ? '#111111' : '#666666',
                    fontWeight: activePage ? 'bold' : 'normal',
                }}
            >
                <div
                    className={classes.tabName}
                >
                    {t(formLayout[tabIndex].prettyName)}
                </div>
            </div>
        );
    };

    const renderTab = (tabData: any, tabIndex: number) => {
        const activePage = tabIndex === currentTabIndex;
        return (
            <div
                key={tabIndex}
                className={classes.tabSelector}
                onClick={() => handleTabChange(tabIndex)}
                style={{
                    borderBottom: activePage
                        ? `4px solid ${uiTheme.secondryColor}` : 'none',
                }}
            >
                {renderTabIcon(tabIndex)}
                {renderTabDetails(tabIndex)}
            </div>
        );
    };

    const renderPageSelector = () => {
        return (
            <div className={classes.pageSelector}>
                {formLayout.map(renderTab)}
            </div>
        );
    };

    async function checkForErrors() {
        try {
            const formValues = await form.validateFields();
            const updatedData = {
                ...formValues,
            };
            if (validateDataUsingValidationBuilder(true)) {
                setCreating(true);
                const apiBody = convertToAPIBodySaveSingle(updatedData, {
                    editData,
                    showRiskSurchargeType,
                    isCustomFieldAllowed: true,
                    customFieldsList,
                });
                const response = await createConsignment(apiBody);
                if (response.isSuccess) {
                    loadconsignments();
                    setSuccess({
                        ...(response?.data || []),
                        showInvoicePrint: showInvoiceOptions
                            && updatedData.consignmentCategory === 'international'
                            && updatedData.courierType === 'non-document',
                    });
                } else {
                    message.error(response.errorMessage);
                }
                setCreating(false);
            }
        } catch (e) {
            console.log(e);
            message.error('Some fields are incomplete / invalid');
        }
    }

    function handleAction(action: ButtonAction) {
        switch (action) {
            case ButtonAction.PREV: return setCurrentTabIndex(currentTabIndex - 1);
            case ButtonAction.NEXT:
                if (validateDataUsingValidationBuilder()) {
                    return setCurrentTabIndex(currentTabIndex + 1);
                }
                return null;
            case ButtonAction.SUBMIT: return checkForErrors();
            default: return null;
        }
    }

    const renderButton = (name: string, action: ButtonAction) => {
        return (
            <Button
                type="link"
                onClick={() => handleAction(action)}
                loading={creating}
                className={classes.nextPreButton}
            >
                {action === ButtonAction.PREV && <LeftOutlined />}
                {name}
                {action === ButtonAction.NEXT && <RightOutlined />}
            </Button>
        );
    };

    const renderSubmitButton = () => {
        return (
            <Button
                type="primary"
                onClick={() => handleAction(ButtonAction.SUBMIT)}
                loading={creating}
                className={classes.newButton}
            >
                {t(ButtonNames.SUBMIT)}
            </Button>
        );
    };

    const renderLeftButton = () => {
        if (currentTabIndex > 0) {
            return renderButton(t(ButtonNames.PREV), ButtonAction.PREV);
        }
        return <></>;
    };

    const renderRightButton = () => {
        if (currentTabIndex < (formLayout?.length) - 1) {
            return renderButton(t(ButtonNames.NEXT), ButtonAction.NEXT);
        }
        return renderSubmitButton();
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: currentTabIndex > 0 ? 'space-between' : 'flex-end',
                }}
                >
                    {renderLeftButton()}
                    {renderRightButton()}
                </div>
            </div>
        );
    };

    const renderCloseConfirmModal = () => {
        return (
            <Modal
                title={(
                    <div style={{ fontWeight: 600 }}>
                        <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                        &nbsp;
                        {t('Confirm Action')}
                    </div>
                )}
                visible={closeConfirm}
                onCancel={() => setCloseConfirm(false)}
                footer={[
                    <Button key="back" type="text" onClick={() => setCloseConfirm(false)}>
                        {t('No, Continue')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={onClose}>
                        {t('Yes, Cancel')}
                    </Button>,
                ]}
            >
                { i18n.exists('exit_cn_create_form')
                    ? t('exit_cn_create_form')
                    : ('Exiting will result in unsaved changes to your consignment creation.'
                    + ' Do you want to cancel adding consignment now?')}
            </Modal>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <Cross onClick={() => setCloseConfirm(true)} alt="close" className={classes.closeIcon} />
                    <span>{editData ? 'Edit Consignment' : t('add_consignment')}</span>
                </div>
                {/* {renderButton(t(ButtonNames.SUBMIT), ButtonAction.SUBMIT)} */}
            </div>
        );
    };

    const isRequired = (isPiece: any, key: string) => {
        const isFTL = actionType === CreateActions.FTLHyperlocal;
        let required = false;
        if (isPiece) {
            required = required || mandatoryPieceDetails[key];
        } else {
            if (consignmentCategory === 'international') {
                required = [
                    formFields.Weight.key,
                    formFields.DeclaredValue.key,
                    formFields.CustomerReference.key,
                ].includes(key);
            }
            if (consignmentCategory === 'international' && itemType === 'non-document') {
                required = required || mandatoryFieldNonDocIntl[key];
            }
            if (consignmentCategory === 'international' && form.getFieldValue('shipmentPurpose') === 'COMMERCIAL') {
                required = required || mandatoryFieldShipmentCommercial[key];
            }
        }
        if (isFTL) {
            required = [
                formFields.MovementType.key,
                formFields.VehicleCategory.key,
            ].includes(key);
        }
        return required;
    };

    const renderPage = (pageData: any, pageIndex: number) => {
        if (pageData) {
            const { sections = [] } = pageData;
            return (
                <div style={{ display: currentTabIndex === pageIndex ? 'block' : 'none' }}>
                    {sections.map((section: any, sectionIndex: number) => {
                        const { fieldsList = [] } = section;
                        return (
                            <>
                                <div className={classes.box}>
                                    <div className={classes.boxTitle}>
                                        <span>{section.prettyName}</span>
                                    </div>
                                    <div className={classes.boxFieldsCol}>
                                        <Row gutter={10}>
                                            {fieldsList.map((fieldId: string) => getFormFieldFromMapping(fieldId, form, {
                                                childClients,
                                                pudoHubs,
                                                categoryList,
                                                contentList,
                                                currencyList,
                                                countryList,
                                                incotermsList,
                                                isRequired,
                                                shipmentPurposeList,
                                                loadTypeList: loadType,
                                                isCurrentTab: currentTabIndex === pageIndex,
                                                isFTL: actionType === CreateActions.FTLHyperlocal,
                                                currentCustomField: customFieldsList.filter((field: any) => field.id === fieldId),
                                                customFieldsList,
                                            }))}
                                        </Row>
                                    </div>
                                </div>
                                {
                                    sectionIndex < sections.length - 1 ? (
                                        <div
                                            style={{
                                                height: 1,
                                                margin: '12px 0px',
                                                width: '100%',
                                                backgroundColor: '#EDEDED',
                                            }}
                                        />
                                    ) : <></>
                                }
                            </>
                        );
                    })}
                </div>
            );
        }
        return <></>;
    };

    const renderPages = () => {
        const pages = [...(formLayout || [])];
        return pages.map(renderPage);
    };

    return (
        <Drawer
            visible
            width="80%"
            title={renderHeader()}
            footer={renderFooter()}
            onClose={() => setCloseConfirm(true)}
            className={classes.main}
            closable={false}
            footerStyle={{
                padding: 0,
                margin: 0,
            }}
        >
            { loading ? <Loader /> : <></> }
            <Form form={form}>
                {renderPageSelector()}
                {renderPages()}
                {renderCloseConfirmModal()}
            </Form>
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;
    const { config, Customer } = master;
    const customerPortalConfig: any = config?.customer_portal_config;
    const shipmentPurposeList: any = config?.shipment_purpose_list;
    const mandatoryFieldNonDocIntl = customerPortalConfig?.mandatory_field_non_document_international || {};
    const mandatoryFieldShipmentCommercial = customerPortalConfig?.mandatory_shipment_commercial || {};
    const mandatoryPieceDetails = customerPortalConfig?.mandatory_piece_details || {};
    const showRiskSurchargeType = customerPortalConfig?.show_risk_surcharge_type;
    const showInvoiceOptions = Customer?.show_invoice_options;
    const useCategory = config?.use_category_in_commodity_data;
    const formLayout = config?.customer_portal_order_creation_config?.form_layout || [];
    const subLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const customFieldsList = config?.custom_fields_list || [];
    return {
        uiTheme: state.uiTheme,
        mandatoryFieldNonDocIntl,
        mandatoryFieldShipmentCommercial,
        mandatoryPieceDetails,
        showRiskSurchargeType,
        showInvoiceOptions,
        useCategory,
        formLayout,
        shipmentPurposeList,
        subLayout,
        customFieldsList,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(singleConsignmentStyles)(GenericHoc(hocConfig)(SingleConsignment));
