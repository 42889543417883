import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../library/globals';
import { GET, POST } from './api-hander.service';
import AuthHelper from '../auth/auth-helper';
import {
    BULK_DELETE_ADDRESS,
    DELETE_ADDRESS,
    DOWNLOAD_PICKUPS,
    EDIT_ADDRESS,
    FETCH_PICKUP_ADDRESS,
    GET_PICKUPS,
    GET_STATUS_FILTER,
    LOAD_TYPE_FILTER,
    SAVE_ADDRESS,
    SAVE_PICKUP,
    SORT_BY_FILTER,
    GET_DEFAULT_ADD,
    SET_DEFAULT_ADD,
    DOWNLOAD_ADDRESSES,
    GET_ADDRESSES,
    DOWNLOAD_ALL_ADDRESSES,
    DOWNLOAD_ADDRESS_SAMPLE,
    BULK_EDIT,
    BULK_SAVE_ADDRESS,
    FETCH_LOCALITY_FROM_ADDRESS_MAPPING,
    FETCH_ADDRESS_FROM_ADDRESS_HIERARCHY,
    FETCH_CITIES_LIST,
    FETCH_COUNTRIES_NODE_DATA,
    FETCH_STATES_LIST,
    UPDATE_PICKUP_COLUMNS,
    FETCH_ADDRESS_NODE_CONFIG,
    FETCH_PINCODES_LIST,
    BULK_PICKUPS,
    FETCH_SUMMARY_COUNT_PICKUP,
    GEOCODE_BY_ADDRESS,
    GET_PICKUP_CONSIGNMENTS,
    MARK_PICKUP_SUCCESS,
    CANCEL_CARRIER_PICKUP,
    FETCH_PICKUP_ADDRESS_PAGINATION,
} from './api.constants';

export const fetchPickupAddress = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_PICKUP_ADDRESS}`, params);
};

export const fetchPickupAddressMultiple = async (body: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_PICKUP_ADDRESS_PAGINATION}`, body);
};

export const saveAddress = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${SAVE_ADDRESS}`, body);
};

export const editAddress = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${EDIT_ADDRESS}`, body);
};

export const savePickup = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${SAVE_PICKUP}`, body, undefined, AuthHelper.getAuthenticationHeaders());
};

export const getPickups = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_PICKUPS}`, params);
};

export const downloadPickups = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_PICKUPS}`, params);
};

export const loadStatuses = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_STATUS_FILTER}`, {});
};

export const loadSortBy = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${SORT_BY_FILTER}`, {});
};

export const getLoadType = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${LOAD_TYPE_FILTER}`, {});
};

export const deleteAddress = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_ADDRESS}`, params);
};
export const setDefaultAddressData = async (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${SET_DEFAULT_ADD}`, params);
};

export const getDefaultAddress = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_DEFAULT_ADD}`);
};

export const bulkDeleteAddress = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_DELETE_ADDRESS}`, params);
};

export const downloadAddresses = (params: any, isBlob: boolean = true) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_ADDRESSES}`, { data: params }, isBlob);
};

export const getAddresses = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_ADDRESSES}`, params);
};

export const downloadAllAddresses = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_ALL_ADDRESSES}`, params, 'blob');
};

export const downloadSampleAddress = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_ADDRESS_SAMPLE}`, params, 'blob');
};

export const bulkEdit = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_EDIT}`, { data: params });
};

export const bulkSaveAddress = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_SAVE_ADDRESS}`, { data: params });
};

export const fetchLocalityFromAddressMapping = (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_LOCALITY_FROM_ADDRESS_MAPPING}`, { pincode: params });
};

export const fetchAddressUsingAddressHierarchy = (params: any) => {
    return POST(`${API_BASE_URL}${FETCH_ADDRESS_FROM_ADDRESS_HIERARCHY}`, params);
};

export const fetchCountriesList = (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_COUNTRIES_NODE_DATA}`,
        { isDataSearchApplied: true, searchQuery: params, sortResult: true });
};

export const fetchCitiesList = () => {
    return GET(`${API_BASE_URL}${FETCH_CITIES_LIST}`);
};

export const fetchCitiesList2 = (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_CITIES_LIST}`,
        { isDataSearchApplied: true, searchQuery: params, sortResult: true });
};

export const fetchStatesList = (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_STATES_LIST}`,
        { isDataSearchApplied: true, searchQuery: params, sortResult: true });
};

export const updateColumns = async (body: any) => {
    return POST(`${API_BASE_URL}${UPDATE_PICKUP_COLUMNS}`, body);
};

export const fetchAddressNodeConfig = () => {
    return GET(`${API_BASE_URL}${FETCH_ADDRESS_NODE_CONFIG}`);
};

export const getPickupConsignments = async (params: any) => {
    return GET(`${API_BASE_URL}${GET_PICKUP_CONSIGNMENTS}`, params);
};

export const fetchPincodeList = (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_PINCODES_LIST}`,
        { isDataSearchApplied: true, searchQuery: params, sortResult: true });
};

export const downloadSamplePickup = () => {
    /* eslint-disable-next-line max-len */
    return GET('https://shipsy-public-assets.s3.us-west-2.amazonaws.com/shipsy/sample-downloads/customer-portal/sample_pickup_upload_booking_portal.xlsx',
        {},
        'blob');
};

export const createBulkPickups = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_PICKUPS}`, params, undefined, AuthHelper.getAuthenticationHeaders());
};

export const fetchSummaryPickups = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${FETCH_SUMMARY_COUNT_PICKUP}`, body);
    return response;
};

export const markPickupAsSuccess = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${MARK_PICKUP_SUCCESS}`, body);
    return response;
};

export const cancelCarrierPickups = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${CANCEL_CARRIER_PICKUP}`, body);
    return response;
};

export const geocodeByAddress = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${GEOCODE_BY_ADDRESS}`, body);
};
